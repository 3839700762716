<template>
  <div>
    <!-- 顶部搜索栏 -->
    <el-row>
      <el-col :xs="24" :sm="24" :lg="24">
        <div class="pad-container flx-row ali-c">
          <el-form :inline="true" :model="searchObj">
              <el-form-item label="优惠券名称" prop="name">
                <el-input
                  placeholder="优惠券名称"
                  class="search-input"
                  v-model="searchObj.name"
                ></el-input>
              </el-form-item>
              <el-form-item label="优惠券内容" prop="preferentialContent">
                <el-input
                  placeholder="优惠券内容"
                  class="search-input"
                  v-model="searchObj.preferentialContent"
                ></el-input>
              </el-form-item>
              <el-form-item label="类型" prop="classifyId">
                <el-select
                  placeholder="类型"
                  class="search-input ml-15"
                  v-model="searchObj.classifyId"
                >
                  <el-option
                    label="全部"
                    value=""
                  ></el-option>
                  <el-option
                    v-for="(item, index) in cou_type"
                    :key="index"
                    :label="item.classifyName"
                    :value="item.classifyId"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="券种" prop="applicableObjects">
                <el-select
                  placeholder="券种"
                  class="search-input ml-15"
                  v-model="searchObj.applicableObjects"
                >
                  <el-option
                    label="全部"
                    value=""
                  ></el-option>
                  <el-option
                    v-for="(item, index) in applicableOption"
                    :key="index"
                    :label="item.name"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="创建时间" prop="activityTime">
                <el-date-picker
                  v-model="activeTime"
                  type="datetimerange"
                  start-placeholder="开始日期"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  format="yyyy-MM-dd HH:mm:ss"
                  end-placeholder="结束日期"
                  @change="changeActiveTime"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item label="优惠券状态" prop="activityTime">
                <el-select
                  placeholder="优惠券状态"
                  class="search-input ml-15"
                  v-model="searchObj.status"
                >
                  <el-option
                    label="全部"
                    value=""
                  ></el-option>
                  <el-option
                    v-for="(item, index) in cou_status"
                    :key="index"
                    :label="item.name"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
            <el-form-item>
               <el-button
                  type="primary"
                  icon="el-icon-search"
                  class="ml-15"
                  @click="initData"
                  >查询</el-button
                >
                <el-button icon="el-icon-refresh" class="ml-15" @click="reset"
                  >重置</el-button
                >
            </el-form-item>
          </el-form>

         
        </div>
      </el-col>
    </el-row>
    <!-- 表格内容 -->
    <el-row class="mt-24">
      <el-col el-col :xs="24" :sm="24" :lg="24">
        <div class="pad-container">
          <!-- 操作栏 -->
          <div class="flx-row ali-c js-btw">
            <div class="flx-row ali-c">
              <router-link
                :to="{ name: 'Coupons_new', params: { addFlag: true } }"
              >
                <el-button class="ml-15" type="primary" icon="el-icon-plus">新建优惠券</el-button>
              </router-link>
            </div>
            <!-- <div class="flx-row ali-c">
              <el-button icon="el-icon-download" type="primary" plain>导入</el-button>
              <el-button icon="el-icon-upload2" class="ml-15" type="primary" plain>导出</el-button>
            </div>-->
          </div>
          <!-- 表格 -->
          <el-table
            class="mt-24 member-table"
            :data="tableData"
            @selection-change="tableSelect"
          >
            <el-table-column label="序号" type="index"></el-table-column>
            <el-table-column label="优惠券名称" prop="name"></el-table-column>
            <el-table-column label="类型">
              <template slot-scope="scope">
                {{
                 scope.row.couponClassifyId==1?"满减券":"折扣券"
                }}
              </template>
            </el-table-column>
            <el-table-column
              label="券种"
              prop="applicableObjectsName"
            ></el-table-column>

            <el-table-column label="发放方式">
              <template slot-scope="scope">
                {{ scope.row.disbursement == 1 ? "免费领取" : "限领" }}
              </template>
            </el-table-column>
            <el-table-column label="优惠内容" prop="preferentialContent"></el-table-column>
            <el-table-column
              label="已领取/已使用/剩余"
            >
             <template slot-scope="scope">
                {{scope.row.getNumber?scope.row.getNumber:0}} /
                {{scope.row.usageNumber?scope.row.usageNumber:0}} /
                {{scope.row.remainingNumber?scope.row.remainingNumber:0}}
              </template>
            </el-table-column>
            <el-table-column
              label="领券时间"
              prop="securitiesTime"
            ></el-table-column>
            <el-table-column label="创建时间" prop="createTime"></el-table-column>
            <el-table-column label="状态" width="140px">
              <template slot-scope="scope">
                <el-switch
                  v-model="scope.row.status"
                  :active-value="1"
                  :inactive-value="0"
                  active-text="上架"
                  inactive-text="下架"
                  @change="upDownShelf($event, scope.row.couponId)"
                ></el-switch>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="200">
              <template slot-scope="scope">
                <div class="flx-row ali-c">
                  <router-link
                    :to="{
                      name: 'Coupons_new',
                      query: { addFlag: false, id: scope.row.couponId },
                    }"
                  >
                    <el-link type="primary" :underline="false">
                      <i class="el-icon-edit-outline"></i>
                          编辑
                    </el-link>
                  </router-link>
                  <el-link
                    tpe="primary"
                    :underline="false"
                    class="ml-10"
                    @click="copyCoup(scope.row)">
                    复制
                  </el-link>
                  <el-link
                    type="primary"
                    :underline="false"
                    class="ml-10"
                    @click="dataCoup(scope.row)">
                    数据
                  </el-link>
                  <el-link
                    type="danger"
                    :underline="false"
                    class="ml-10"
                    @click="deleteCoup(scope.row.couponId)"
                  >
                    <i class="el-icon-delete-solid"></i>
                    删除
                  </el-link>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <Pagination
            :total="total"
            :pageNum="pageNum"
            :pageSize="pageSize"
            :pageSizes="pageSizes"
            @changePage="initData"
            @syncPageData="syncPageData"
          />
        </div>
      </el-col>
    </el-row>

    <!-- 复制优惠券 -->
    <el-dialog title="复制优惠券" :visible.sync="copyCoupDialog">
      <el-form :model="copyForm" :rules="copyFormRule">
        <el-form-item label="优惠券名称" label-width="150px">
          <span>{{copyCoupOldName}}</span>
        </el-form-item>
        <el-form-item label="优惠券内容" label-width="150px">
          <span>{{copyCoupContent}}</span>
        </el-form-item>
        <!-- <el-form-item label="新建优惠券名称" prop="name" label-width="150px">
           <el-input v-model="copyForm.name" autocomplete="off"></el-input>
        </el-form-item> -->
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="copyCoupDialog = false">取 消</el-button>
        <el-button type="primary" @click="submitCopyForm">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 优惠券数据 -->
    <CouponsDetails ref="CouponsDetails" :detail="couponsDetail"></CouponsDetails>
  </div>
</template>
<script>
import Pagination from "@/components/Pagination";
import CouponsDetails from "@/components/CouponsDetails";
import {
  getCouponClassify,
  getCouponList,
  upDownShelfCoupon,
  deleteCoupon,
  copyLrCoupon
} from "@/api/maketing/coupons";
export default {
  name: "members_list",
  components: {
    Pagination,
    CouponsDetails
  },
  data() {
    return {
      // 顶部搜索栏数据
      activeTime: "",
      searchObj: {
        name: "",
        preferentialContent:"",
        classifyId:"",
        applicableObjects:'',
        status:'',
        startTime:"",
        endTime:""
      },
      // 优惠券状态列表,用于筛选
      cou_status: [
        { id: 1, name: "上架" },
        { id: 0, name: "下架" },
      ],
      // 优惠券使用品类列表,用于筛选
      // 优惠券表格数据内容
      tableData: [],
      createUser: "",
      addFlag: true,
      total: 0,
      pageNum: 1,
      pageSize: 10,
      pageSizes: [10, 20, 30, 50, 100],
      applicableOption:[
        {
          id:1,
          name:"全场券"
        },
        {
          id:2,
          name:"单品券"
        },
        {
          id:3,
          name:"品类券"
        },
        {
          id:4,
          name:"品牌券"
        }
      ],
      couponType: [
        {
          type: 1,
          name: "通用券",
        },
        {
          type: 2,
          name: "商品劵",
        },
        {
          type: 3,
          name: "餐饮优惠券",
        },
        {
          type: 4,
          name: "折扣券",
        },
        {
          type: 5,
          name: "代金券",
        },
        {
          type: 6,
          name: "停车券",
        },
      ],
      cou_type:[
        {
          classifyId: 1,
          classifyName: "满减券",
        },
        {
          classifyId: 2,
          classifyName: "折扣券",
        },
      ],
      copyForm:{ //复制优惠券的表单
        couponId:'',
        name:''
      },
      copyFormRule:{
        name:[
          { required: true, message: "请输入新优惠券的名称！", trigger: "blur" },
        ]
      },
      copyCoupOldName:"",//优惠券名称
      copyCoupContent:"",//优惠券内容
      copyCoupDialog:false, //复制优惠券弹窗
      couponsDetail:{} //选择的优惠券内容
    };
  },
  mounted() {
    this.initData(1);
    // this.getType()
  },
  methods: {
    //获取优惠券品类
    getType() {
      getCouponClassify().then((res) => {
        this.cou_type = res.data.body.list;
      });
    },
    //获取所有优惠券
    initData(type) {
      if (type == 1) {
        this.pageNum = 1;
        this.pageSize = 10;
      }
      let data = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        ...this.searchObj,
      };

      getCouponList(data).then((res) => {
        // // console.log(res);
        if (res.data.code == 200) {
          this.total = res.data.body.total;
          this.tableData = res.data.body.list;
        }
      });
    },
    // 重置搜索关键词
    reset() {
      this.searchObj = {
        searchKey: "",
        status: null,
      };
      this.initData(1);
    },
    //上下架
    upDownShelf(status, id) {
      console.log(status, id);
      upDownShelfCoupon(id, status)
        .then((res) => {
          this.$message({
            message: "操作成功",
            type: "success",
          });
        })
        .catch((err) => {
          this.initData();
        });
    },
    // 复制优惠券
    copyCoup(data){
      this.copyForm.couponId=data.couponId
      this.copyForm.name=""
      this.copyCoupDialog=true
      this.copyCoupOldName = data.name
      this.copyCoupContent = data.preferentialContent
    },
    // 提交复制优惠券的表单
    async submitCopyForm(couponId){
      // if(!this.copyForm.name){
      //   this.copyForm.name = this.copyCoupOldName + 1
      // }
      // let res = await copyLrCoupon(this.copyForm)
      // this.$message({
      //   message: "操作成功",
      //   type: "success",
      // });
      // this.copyCoupDialog=false
      // this.initData();
      this.$router.push({
        name: 'Coupons_new',
        query: { addFlag: false, id: this.copyForm.couponId,isCopy:true },
      })
    },
    // 优惠券数据
    dataCoup(data){
      this.$refs.CouponsDetails.coupDataDialog = true
      this.$refs.CouponsDetails.coupDetail = data
      this.$refs.CouponsDetails.couponId = data.couponId
      this.$refs.CouponsDetails.initData(1)
    },
    //删除优惠券
    deleteCoup(id) {
      this.$confirm("此操作将永久删除该优惠券, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(() => {
          deleteCoupon(id).then((res) => {
            if (res.data.code == 200) {
              this.$message({
                message: "删除成功",
                type: "success",
              });
              this.initData();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 会员多选事件
    tableSelect(e) {
      // // console.log(e);
      this.multiSeletction = e;
    },
    // 优惠券多选事件
    selectCou(e) {
      this.selectedCou = e;
    },
    syncPageData(data) {
      this.pageNum = data.num;
      this.pageSize = data.size;
      this.initData();
    },
    changeActiveTime(val) {
      if(val&&val.length>0){
        this.searchObj.startTime = val[0];
        this.searchObj.endTime = val[1];
      }else{
        this.searchObj.startTime = '';
        this.searchObj.endTime = '';
      }
      
    },
  },
};
</script>
<style lang="scss" scoped>
.member-table {
  .circle {
    width: 6px;
    height: 6px;
    border-radius: 50%;
  }
}
</style>
