<template>
    <el-dialog title="查看优惠券数据" :visible.sync="coupDataDialog" width="800px" top="10vh">
      <el-form inline label-width="160px">
        <el-row>
          <el-col :span="14">
            <el-form-item label="优惠券名称:" >
              <span>{{coupDetail.name}}</span>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="类型:" label-width="100px">
              <span>{{coupDetail.couponClassifyName}}</span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
            <el-col :span="14">
              <el-form-item label="优惠内容:">
                <span>{{coupDetail.preferentialContent}}</span>
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-form-item label="状态:" label-width="100px">
                <span>{{coupDetail.status==1?"上架":"下架"}}</span>
              </el-form-item>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="14">
                <el-form-item label="发放方式:">
                <span>{{ coupDetail.disbursement == 1 ? "免费领取" : "限领" }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-form-item label="创建时间:" label-width="100px">
                <span>{{coupDetail.createTime}}</span>
              </el-form-item>
            </el-col>
        </el-row>
        
        <el-row>
            <el-col :span="14">
                <el-form-item label="已领取 / 已使用 / 剩余:">
                  <span> {{coupDetail.getNumber?coupDetail.getNumber:0}} /
                        {{coupDetail.usageNumber?coupDetail.usageNumber:0}} /
                        {{coupDetail.remainingNumber?coupDetail.remainingNumber:0}}</span>
                </el-form-item>
            </el-col>
        </el-row>
      </el-form>
       <el-table
        :data="tableData"
        border
        style="width: 100%"
        height="300px">
        <el-table-column
          prop="cmname"
          label="领用用户"
          >
        </el-table-column>
        <el-table-column
          prop="couponStatus"
          label="优惠券状态"
          >
        </el-table-column>
        <el-table-column
          prop="receiveTime"
          label="领取时间">
        </el-table-column>
        <el-table-column
          prop="offsetTime"
          label="使用时间">
        </el-table-column>
        <el-table-column
          prop="validEndTime"
          label="过期时间">
        </el-table-column>
        <el-table-column
          prop="orderNumber"
          label="关联订单">
          <template slot-scope="scope">
              <span>{{scope.row.orderNumber}}</span><br>
              <span v-if="scope.row.orderStatus==6" style="color:red;">(用户取消订单)</span>
          </template>
        </el-table-column>
      </el-table>
      <Pagination
            :total="total"
            :pageNum="pageNum"
            :pageSize="pageSize"
            :pageSizes="pageSizes"
            @changePage="initData"
            @syncPageData="syncPageData"
          />
      <div slot="footer" class="dialog-footer">
        <el-button @click="exportCoupData">导 出</el-button>
        <el-button @click="coupDataDialog = false">取 消</el-button>
        <el-button type="primary" @click="coupDataDialog = false">确 定</el-button>
      </div>
    </el-dialog>
</template>
<script>
import Pagination from "@/components/Pagination";
import { queryRelationCoupon,exportCoupon } from "@/api/maketing/coupons";
export default {
    components:{
      Pagination
    },
    data(){
        return{
            coupDataDialog:false,
            coupDetail:{},
            tableData:[],
            couponId:null,
            total: 0,
            pageNum: 1,
            pageSize: 10,
            pageSizes: [10, 20, 30, 50, 100],
        }
    },
    mounted() {
      // this.initData(1);
    },
    methods:{
      async initData(type){
        if (type == 1) {
          this.pageNum = 1;
          this.pageSize = 10;
        }
        let data = {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          couponId:this.couponId
        };
        let res = await queryRelationCoupon(data)
        if (res.data.code == 200) {
          this.total = res.data.body.total;
          this.tableData = res.data.body.list;
        }
      },
      syncPageData(data) {
        this.pageNum = data.num;
        this.pageSize = data.size;
        this.initData();
      },
      async exportCoupData(){
          // let url = `/api/customer-service/lrCoupon/exportRelationCoupon?couponId=${this.couponId}`
          // window.open(url, "_blank");
          let url = '/customer-service/lrCoupon/exportRelationCoupon?couponId='+this.couponId
          this.downFile(url, `优惠券详情${this.couponId}`, {}, 'get')
      }
    }
}
</script>